import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Box,
  Modal,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import { useResponsive } from "../../hooks/customUseResponsive";
import { localStorageInstance } from "../../utils/localStorage";
import { useNavigate } from "react-router-dom";
import RHFTextField from "../RFH/RFHtextfield";
import { LoadingButton } from "@mui/lab";

const schema = yup.object().shape({
  reason: yup.string().required("Please enter reason."),
});

export default function RejectRequestModal({
  openProp,
  handleClose,
  handleConfirm,
}) {
  const isMobile = useResponsive("down", "sm");
  const [loading, setLoading] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "327px" : 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowX: "auto",
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: "",
    },
  });
  const onSubmit = (formData) => {
    setLoading(true);
    handleConfirm(formData.reason);
    methods.setValue("reason", "");
    setLoading(false);
  };
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Reject Request</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>

            <Stack className={styles.modalBody}>
              <Typography className={styles.subHead}>Reason</Typography>
              <RHFTextField multiline rows={4} name="reason" />
              <Stack className={styles.btnCont}>
                <Button
                  variant="outlined"
                  className={styles.cancelBtn}
                  onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  className={styles.logoutBtn}
                  type="submit">
                  Reject
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
