import { localStorageInstance } from "./localStorage";

export function formatCurrency(value) {
  return new Intl.NumberFormat("en", {
    style: "currency",
    currency: "EUR",
  }).format(value);
}

export function formatDate(dateStr) {
  return new Intl.DateTimeFormat("en", {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(dateStr));
}

export function calcMinutesLeft(dateStr) {
  const d1 = new Date().getTime();
  const d2 = new Date(dateStr).getTime();
  return Math.round((d2 - d1) / 60000);
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Iterate through the object's keys
  for (let key in obj) {
    // Check if the key is a property of the object (not inherited)
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}

export function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
}

export function createAvtarName(username) {
  return username
    .split(" ")
    .map((el, i) => (i < 2 ? el[0] : ""))
    .join("");
}

export const isAdmin=()=>{
  return localStorageInstance.getItem('role') == 'admin'
}