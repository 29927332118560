import {
  Box,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import AddAttendanceSchema from "../../../../schemas/addAttendanceSchema.js";
import RHFRadioGroup from "../../../../components/RFH/RFHradioGroup.js";
import RHFTimePicker from "../../../../components/RFH/RFHtimePicker.js";
import AddCompOff from "../../../../schemas/addCompoff.js";
import { addCompOffForEmployee } from "../../../../services/manager/compOff/apiAddCompoff.js";
import GetCompOffDate from "../../../../utils/reactQuery/manager/compoOff/GetCompOffDates.js";

export default function AddCompOffModal({ openProp, handleClose, listOfEmp }) {
  const [loading, setLoading] = useState(false);
  const isMobile = useResponsive("down", "sm");
  const queryClient = useQueryClient();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    application_type: "Comp_Off",
  };
  const methods = useForm({
    resolver: yupResolver(AddCompOff),
    defaultValues,
  });
  const { availableCompOffs } = GetCompOffDate(methods.watch("employee_id"));
  console.log(
    "list of date available == ",
    availableCompOffs?.data,
    methods.watch("employee_id"),
    methods.getValues("employee_id")
  );

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    setLoading(true);
    // mutate({ ...formData });
    addCompOffForEmployee({
      ...formData,
      date: `${new Date(formData.date).getFullYear()}-${
        new Date(formData.date).getMonth() + 1 < 10
          ? "0" + (new Date(formData.date).getMonth() + 1)
          : new Date(formData.date).getMonth() + 1
      }-${
        new Date(formData.date).getDate() < 10
          ? "0" + new Date(formData.date).getDate()
          : new Date(formData.date).getDate()
      }`,
      dateLeave: `${new Date(formData.dateLeave).getFullYear()}-${
        new Date(formData.dateLeave).getMonth() + 1 < 10
          ? "0" + (new Date(formData.dateLeave).getMonth() + 1)
          : new Date(formData.dateLeave).getMonth() + 1
      }-${
        new Date(formData.dateLeave).getDate() < 10
          ? "0" + new Date(formData.dateLeave).getDate()
          : new Date(formData.dateLeave).getDate()
      }`,
    })
      .then((res) => {
        console.log("add compoff for emplyee response == ", res);
        toast.success(res.data.success);
        queryClient.invalidateQueries({
          queryKey: ["CompOffRequest"],
        });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log("add compoff for employee error == ", err);
        toast.error(err.response.data.error);
        setLoading(false);
      });
  };

  console.log("errors === ", methods.formState.errors);
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                Add Employee CompOff
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              <Box>
                <Typography className={styles.label}>
                  Select Employee
                </Typography>
                <RHFSelect name="employee_id">
                  {listOfEmp.map((ele, idx) => {
                    return (
                      <MenuItem value={ele.employee_id} key={idx}>
                        {ele.name}
                      </MenuItem>
                    );
                  })}
                </RHFSelect>
              </Box>
              <Box>
                <Typography className={styles.label}>Date</Typography>

                <Stack className={styles.inputFeild} direction="row">
                  <RHFSelect name="date">
                    {availableCompOffs?.data &&
                      availableCompOffs.data?.map((ele, idx) => {
                        return (
                          <MenuItem value={ele.date} key={idx}>
                            {ele.formatted_date}
                          </MenuItem>
                        );
                      })}
                  </RHFSelect>
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>Leave Date</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="dateLeave" />
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="reason"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Apply
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
