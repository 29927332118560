import { isAdmin } from "../../../utils/helpers";
import Api from "../../axios";

export const postApproveLeaveRequest = async (id) => {
  return await Api.post("/approve_leave", {
    application_id: id,
    is_approved_by_manager: isAdmin() ? false : true,
    is_approved_by_hr: isAdmin() ? true : false,
  });
};
