import { useQuery } from "@tanstack/react-query"

import { getMissingStatusList } from "../../../../services/manager/count/apiMissingStatus"
import { usDateFormate } from "../../../../hooks/dateFormat"

export const MissingStatusQuery=({startDate, endDate})=>{
    const {data, isLoading} = useQuery({
        queryKey: ['missingStatus',startDate,endDate],
        queryFn: ()=>getMissingStatusList({startDate: startDate, endDate: endDate})
    })
    let missingStatus = data?.data?.data?.responseData.map((ele,idx)=>{
        return{
            id: idx,
            col1: ele.username,
            col2: usDateFormate(ele.date.slice(0,10)),
            col3: ele.total_hours_spent,
            col4: ele.delay
        }
    })
    
    // if(data?.data.message){
    //     // toast.error(data.data.message)
    // }else{
    // missingEntries = data?.data.map((ele,idx)=>{
    //     return{
    //         id: idx,
    //         col1: ele.employee_name,
    //         col2: usDateFormate(ele.date? ele.date : ''),
    //         col3: {id: ele.employee_id, date: ele.date}
    //     }
    // })}

    return {missingStatus, isLoading}
}