import { useQuery } from "@tanstack/react-query";
import { getCompOffRequest } from "../../../../services/manager/apiCompOffRequest";
import { usDateFormate } from "../../../../hooks/dateFormat";

export default function CompOffRequestQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["CompOffRequest"],
    queryFn: () => getCompOffRequest(),
  });

  const CompOffList = data?.data.compoff_list.map((ele, idx) => {
    console.log("compOff isPast == ", new Date(ele.leave_date));
    return {
      id: idx,
      col1: ele.employee_name,
      col2: ele.work_date ? usDateFormate(ele.work_date) : "",
      col3: ele.leave_date ? usDateFormate(ele.leave_date) : "",
      col4: ele.applied_on ? usDateFormate(ele.applied_on) : "",
      col5: ele.status,
      col6: {
        id: ele.application_id,
        status: ele.status,
        isPast: new Date() >= new Date(ele.leave_date),
      },
    };
  });
  return { CompOffList, isLoading, refetch };
}
