import * as Yup from "yup";

const AddCompOff = Yup.object().shape({
  employee_id: Yup.string().required("Please select employee."),
  date: Yup.date().required("Please select date."),
  reason: Yup.string().required("Please enter reason."),
  application_type: Yup.string().required("Type is required"),
  dateLeave: Yup.string().required("Please enter leave date.")
});

export default AddCompOff;
