import { Box, MenuItem, Modal, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../../../../components/DatePickerComp/DatePickerComp";
import RHFTextField from "../../../../components/RFH/RFHtextfield.js";
import { RHFSelect } from "../../../../components/RFH/RFHselect.js";
import { addLeaveSchema } from "../../../../schemas/addLeaveSchema.js";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../../../hooks/customUseResponsive.js";
import {
  addEmployeeLeave,
  postLeaveApp,
} from "../../../../services/leave/apiAddLeaveApp.js";
import { addAdminLeaveSchema } from "../../../../schemas/addAdminLeaveSchema.js";

export default function AddLeaveModal({ openProp, handleClose, listOfEmp }) {
  const queryClient = useQueryClient();
  const isMobile = useResponsive("down", "sm");
  console.log("list of employee under manager", listOfEmp);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const defaultValues = {
    startTime: "first_half",
    endTime: "first_half",
    applicationType: "Casual",
  };
  const methods = useForm({
    resolver: yupResolver(listOfEmp ? addAdminLeaveSchema : addLeaveSchema),
    defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: listOfEmp ? addEmployeeLeave : postLeaveApp,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset();
      queryClient.invalidateQueries({
        queryKey: listOfEmp ? ["leaveRequest"] : ["leaveLogList"],
      });
      handleClose();
    },
    onError: (data) => {
      console.log("error --- ", data.response.data.error);
      toast.error(data.response.data.error);
    },
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    mutate({ ...formData });
  };
  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Add Leave</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              {listOfEmp && (
                <Box>
                  <Typography className={styles.label}>
                    Select Employee
                  </Typography>
                  <RHFSelect name="employeeId">
                    {listOfEmp.map((ele, idx) => {
                      return (
                        <MenuItem value={ele.employee_id} key={idx}>
                          {ele.name}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                </Box>
              )}
              <Box>
                <Typography className={styles.label}>From</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="startDate" />
                  <RHFSelect name="startTime" className={styles.selectComp}>
                    {[
                      <MenuItem value="first_half" key="1">
                        First Half
                      </MenuItem>,

                      <MenuItem value="second_half" key="2">
                        Second Half
                      </MenuItem>,
                    ]}
                  </RHFSelect>
                </Stack>
              </Box>

              <Box>
                <Typography className={styles.label}>To</Typography>
                <Stack className={styles.inputFeild} direction="row">
                  <DatePickerComponent name="endDate" />
                  <RHFSelect className={styles.selectComp} name="endTime">
                    {[
                      <MenuItem
                        value="first_half"
                        key="1"
                        className={styles.menuItem}>
                        First Half
                      </MenuItem>,

                      <MenuItem
                        value="second_half"
                        key="2"
                        className={styles.menuItem}>
                        Second Half
                      </MenuItem>,
                    ]}
                  </RHFSelect>
                </Stack>
              </Box>

              {/* <Box>
                <Typography className={styles.label}>Leave Type</Typography>

                <RHFTextField
                  name="applicationType"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: isMobile ? "30px !important" : "40px !important",
                      backgroundColor: "#fff",
                      fontSize: isMobile ? "12px !important" : "",
                    },
                  }}
                  disabled
                />
              </Box> */}

              <Box>
                <Typography className={styles.label}>Message</Typography>
                <RHFTextField
                  name="message"
                  multiline
                  rows={4}
                  fullWidth
                  className={styles.textarea}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#fff !important",
                    },
                  }}
                />
              </Box>
              <Stack alignItems="end">
                <LoadingButton
                  loading={isPending}
                  variant="contained"
                  type="submit"
                  className={styles.btn}>
                  Apply
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
