import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "./styles.module.css";
import Default from "../../components/Avatar";
import sofmenLogo from "../../styles/svg/sofmenLogo.svg";
import AccountPopOver from "../../components/AccountPopover/AccountPopOver";
import Profile from "../../components/Profile/Profile";
import ConfirmModal from "../../components/ConfirmationModal/ConfirmModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageInstance } from "../../utils/localStorage";
import { CssBaseline, Stack, Tooltip } from "@mui/material";
import TeamDashboardIcon from "../../styles/icons/teamDashboard.svg";
import UpcomingLeaveIcon from "../../styles/icons/upcomingLeave.svg";
import LeaveRequestIcon from "../../styles/icons/leaveRequestIcon.svg";
import CompOffIcon from "../../styles/icons/compOffIcon.svg";
import EmployeeDetailIcon from "../../styles/icons/employeeDetailIcon.svg";
import CardSection from "./CardSection/CardSection";
import UpcomingLeaves from "./UpcomingLeaves.js/UpcomingLeaves";
import LeaveRequests from "./LeaveRequests.js/LeaveRequests";
import AttendanceRequest from "./AttendanceRequest.js/AttendanceRequest";
import CompOffRequest from "./CompOffRequest/CompOffRequest";
import EmployeesDetail from "./EmployeesDetail/EmployeesDetail";
import EmployeePerformanceFeedback from "./EmployeesPerformanceFeedback/EmployeesPerformanceFeedback";
import { useQueryClient } from "@tanstack/react-query";
import { ShowUserData } from "../../utils/reactQuery/ShowUserData";
import MissingStatusModal from "./MissingStatusModal";
import { ShortDaysCount } from "../../utils/reactQuery/manager/count/ShortDaysCount";
import { MissingEntryCount } from "../../utils/reactQuery/manager/count/MissingEntryCount";
import { useResponsive } from "../../hooks/customUseResponsive";
import Navbar from "../../components/Navbar/Navbar";
import Policies from "../Dashboard/Policies/Policies";
import Notices from "../Dashboard/Notices/Notices";
import AdminPolicies from "./AdminPolicies";
import AdminNotices from "./AdminNotices";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: "65.5px",
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const listItems = [
  { content: "Team Dashboard", icon: TeamDashboardIcon, id: "teamDashboard" },
  { content: "Upcoming Leaves", icon: UpcomingLeaveIcon, id: "upcomingLeaves" },
  { content: "Leave  Request", icon: LeaveRequestIcon, id: "leaveRequest" },
  {
    content: " Attendance Request ",
    icon: LeaveRequestIcon,
    id: "attendanceRequest",
  },
  { content: "Comp Off Request", icon: CompOffIcon, id: "compOffRequest" },
  {
    content: "Employees Detail",
    icon: EmployeeDetailIcon,
    id: "employeeDetail",
  },
  {
    content: "Employees Performance Feedback",
    icon: TeamDashboardIcon,
    id: "employeesPerformanceFeedback",
  },
];

export default function ManageTeam() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openAcc, setOpenAcc] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const queryClient = useQueryClient();
  const { user } = ShowUserData();
  const isMobile = useResponsive("down", "sm");

  const handleLogOut = () => {
    localStorageInstance.clear();
    queryClient.clear();
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: isMobile ? "block" : "flex" }}>
      <CssBaseline />
      {isMobile ? (
        <Navbar />
      ) : (
        <Box>
          <AppBar position="fixed" open={open} className={styles.mainAppBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}>
                <MenuIcon sx={{ color: "#000" }} />
              </IconButton>
              <Box className={styles.logoCont}>
                {open ? (
                  <IconButton
                    onClick={handleDrawerClose}
                    className={styles.leftIconCont}>
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon className={styles.leftIcon} />
                    )}
                  </IconButton>
                ) : (
                  <img
                    className={styles.logo}
                    src={sofmenLogo}
                    alt="sofmen logo"
                  />
                )}
              </Box>

              <Stack
                direction="row"
                onClick={(event) => setAnchorEl(event.currentTarget)}>
                {/* <SearchComp /> */}

                <Box
                  onClick={(event) => setOpenAcc(true)}
                  sx={{ cursor: "pointer" }}>
                  <Default name={user?.name} designation={user?.position} />
                </Box>
              </Stack>

              <AccountPopOver
                openpop={openAcc}
                handleProfile={() => setProfileModal(true)}
                handleClose={() => setOpenAcc(false)}
                handleLogout={() => setLogoutModal(true)}
                anchorEl={anchorEl}
              />
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            open={open}
            className={styles.sideBar}
            sx={{
              // width: 253,
              "& .MuiDrawer-paper": {
                border: "none !important",
                backgroundColor: "#f1f2f7",
              },
            }}>
            <DrawerHeader
              sx={{
                backgroundColor: "#fff",
                minHeight: "65.5px",
                paddingBottom: "1px",
                borderBottom: "2px solid",
                paddingRight: "0px !important",
              }}>
              {/* <DrawerHeader> */}
              <img
                src={sofmenLogo}
                alt="sofmen logo"
                className={styles.logoInDrawer}
              />
            </DrawerHeader>
            {/* <Divider /> */}
            <List className={styles.listCont}>
              {listItems.map((ele, index) => (
                <a href={`#${ele.id}`} key={index}>
                  <ListItem
                    disablePadding
                    className={open ? styles.listItem : styles.closedListItem}
                    key={ele.content}>
                    <ListItemButton
                      sx={{
                        minHeight: 45,
                        justifyContent: open ? "initial" : "center",
                        padding: "0px 3px 0px 16px",
                      }}>
                      <Tooltip title={ele.content}>
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 1 : "auto",
                            justifyContent: "center",
                          }}
                          className={styles.iconCont}>
                          <img src={ele.icon} alt="icon" />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText
                        className={styles.content}
                        primary={ele.content}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </a>
              ))}
            </List>
          </Drawer>{" "}
        </Box>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: isMobile ? 2 : 5,
          boxSizing: "initial",
          minWidth: "300px",
          maxWidth: "1480px",
        }}>
        {isMobile ? "" : <DrawerHeader />}
        <Box>
          <CardSection />

          <UpcomingLeaves />

          <LeaveRequests />

          <AttendanceRequest />

          <CompOffRequest />

          <EmployeesDetail />

          <EmployeePerformanceFeedback />

          {/* {localStorageInstance.getItem("role") === "admin" && (
            <div>
              <AdminPolicies />
              <AdminNotices />
            </div>
          )} */}
        </Box>
      </Box>

      <Profile
        openProp={profileModal}
        handleClose={() => setProfileModal(false)}
      />
      {logoutModal ? (
        <ConfirmModal
          openProp={logoutModal}
          handleClose={() => setLogoutModal(false)}
          handleConfirm={handleLogOut}
          tabHeader="Logout"
          heading="Are You Logging Out?"
          content="Are you sure you want to Logout ?"
          btnText="log out"
        />
      ) : (
        ""
      )}
    </Box>
  );
}
