import * as yup from "yup";

export const addLeaveSchema = yup.object().shape({
    startDate: yup.date().required("Please enter start date."),
    endDate: yup
      .date()
      .required("Please enter end date.")
      .min(yup.ref("startDate"), "End date cannot be before start date."),
      message: yup.string().required('Please enter message.'),
      startTime: yup.string().required('required'),
      endTime: yup.string().required('required'),
      applicationType: yup.string().required('required')
  });
