import { useQuery } from "@tanstack/react-query";
import { getCompOffRequest } from "../../../../services/manager/apiCompOffRequest";
import { usDateFormate } from "../../../../hooks/dateFormat";
import { getCompOffDate } from "../../../../services/manager/compOff/apiGetCompoffDate";

export default function GetCompOffDate(id) {
  const {
    data: availableCompOffs,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["CompOffDate",id],
    queryFn: () => getCompOffDate(id),
  });

  return { availableCompOffs, isLoading, refetch };
}
